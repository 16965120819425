import httpClient from "../../services/httpClient";
import { navigate } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setDate, setUsername } from "../../reducers/certificationSlice";
import "../../assets/styles/Assets.css";

const AssetsPage = ({ module }) => {
  const [loading, setLoading] = useState(true);
  const header = module?.response.header;
  const text = module?.response.text ? module?.response.text : [];
  const pdf = module?.digitalAssets.file.url;
  const dispatch = useDispatch();

  useEffect(() => {
    if (module === null) {
      navigate("/", { replace: true });
    }
    const getUserInfo = async () => {
      try {
        const response = await httpClient("/me", {}, "get", "https://centralizedlogin.rplusrprogram.com");
        if (response?.status === 200) {
          return response.data.result;
        } else {
          console.error('Error: ', response?.statusText || 'Unknown error');
          return null;
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        return null;
      }
    };    
    const postCertificationDates = async () => {
      const response = await httpClient("/certification_dates", {}, "post");
      if (response?.status === 200) {
        return response.data.result.certDate.cert_date;
      } else {
        return null;
      }
    };
    const setParams = async () => {
      const userName = await getUserInfo();
      console.log('details',userName)
      const date = await postCertificationDates();
      setTimeout(() => {
        dispatch(
          setUsername(
            userName
              ? `${userName.first_name} ${
                  userName.last_name ? userName.last_name : ""
                }`
              : ""
          )
        );
        dispatch(setDate(date));
        setTimeout(() => {
            setLoading(false);
        },1500);
      }, 0);
    };
    setParams();
  }, []);
  return (
    <div
      className="font-sans relative flex flex-col justify-center items-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${module?.image.url})`,
      }}
    >
      <div className="text-white font-semibold text-2xl text-center w-4/5">
        <h1 className="mb-16 text-4xl">{header}</h1>
        {text.map((curr_txt, index) => {
          return (
            <div className="text-3xl" key={index}>
              <p>{curr_txt.textBody}</p>
            </div>
          );
        })}
      </div>
      <div
        className={
          loading ? "disabled block mt-20 md:flex" : "block mt-20 md:flex"
        }
      >
        <a
          href={!loading ? "/certification/" : ""}
          target="_blank"
          className="cursor-pointer bg-white text-app-title min-h-[5rem] p-4 col-span-3 md:col-span-1 flex items-center justify-center font-bold text-xl md:text-3xl w-72 text-center"
          rel="noopener noreferrer"
        >
          Generate Certificate
        </a>

        <div className="pl-0 pt-4 md:pt-0 md:pl-5">
          <a
            className="cursor-pointer bg-white text-app-title min-h-[5rem] p-4 col-span-3 md:col-span-1 flex items-center justify-center font-bold text-xl md:text-3xl w-72 text-center"
            href={pdf}
            target="_blank"
            rel="noreferrer"
            download="digital_assets.pdf"
          >
            Download Digital Assets
          </a>
        </div>
      </div>
    </div>
  );
};

export default AssetsPage;
