import React from "react";
import AppPage from "../components/AppPage";
import AssetsPage from "../components/digitalAssets/AssetsPage";

const DigitalAssets = ({ location }) => {
  const module = location.state ? location.state.module : null;
  return (
    <AppPage>
      <AssetsPage module={module} />
    </AppPage>
  );
};
export default DigitalAssets;
