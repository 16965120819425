import axios from "axios";

const httpClient = async (url, data = {}, method = "get", baseURL = null) => {
  let config = {
    url,
    data,
    method,
  };
  if (method === "get") {
    config.params = data;
  } else {
    config.data = data;
  }
  if (baseURL) {
    config.baseURL = baseURL;
  }
  try {
    return await axios(config);
  } catch (e) {
    console.log(e);
  }
};

export default httpClient;
